import { createContext } from 'react'
import { makeAutoObservable } from 'mobx'
import * as Tone from 'tone'

import presetCoreCube from 'presets/core-cube-set.json'
const AFRAME = window.AFRAME

class Marker {
  constructor (options) {
    makeAutoObservable(this)

    const {
      name,
      synth,
      note,
      ar_pattern
    } = options

    this.name = name
    this.synth = synth
    this.note = note
    this.ar_pattern = ar_pattern
  }

  play () {
   this.synth.triggerAttackRelease(this.note.frequency,this.note.duration,this.note.when)
  }
}

class Data {
  constructor (rootStore) {
    makeAutoObservable(this)
  }

  isSetup = false

  init = async () => {
    this.membrane = new Tone.MembraneSynth().toDestination()
    this.noise = new Tone.NoiseSynth().toDestination()
    this.metal = new Tone.MetalSynth().toDestination()

    this.preset = presetCoreCube
    this.registerAframeComponents(this.preset)

    await Tone.start()
    this.isSetup = true
  }

  registerAframeComponents = (preset) => {
    AFRAME.registerComponent('init-marker', {
      init: function () {
        const scene = this.el

        for (let i = 0; i < preset.markers.length; i++) {
          const marker = preset.markers[i]
          const el = document.createElement('a-marker')
          el.setAttribute('type','pattern')
          el.setAttribute('preset','custom')
          el.setAttribute('url',preset.ar_patternRootpath+marker.ar_pattern)
          el.setAttribute('raycaster',"objects: .clickable")
          el.setAttribute('emitevents',"true")
          el.setAttribute('cursor',"fuse: false; rayOrigin: mouse;")
          el.setAttribute('preset-position',i)

          el.addEventListener('markerFound',() => {
            console.log('found');
          })
          el.addEventListener('markerLost',() => {
            console.log('lost');
          })
          scene.appendChild(el)
        }
      }
    })
  }

}

export default Data
