import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'

import TRANSLATION_TRACKER_DE from 'locales/de/tracker.json'

const DE = {
  translation:{
   tracker:TRANSLATION_TRACKER_DE
  }
}


const resources = {
  de:DE
}


i18next
 .use(initReactI18next)
 .init({
  resources,
  saveMissing: true,
  interpolation: {
    escapeValue: false
  },
  react: {
    wait: true
  }
 })

 i18next.changeLanguage('de')



export default i18next
