import { useEffect,useRef,useCallback,useContext } from 'react'
import { observer } from 'mobx-react-lite'
import RootStore from './stores/root'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'

import {
  Button,
  Grid
} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
 root:{
   height:'100vh',
   width:'100%',
   overflow:'hidden'
 },
 container:{
   height:'100%'
 }
}))

const MarkerTracker = observer(() => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { markersData } = useContext(RootStore)

  console.log(markersData.isSetup);

  return (
   <div className={classes.root}>
    {
     markersData.isSetup
     ?
     <a-scene
     vr-mode-ui="enabled: false;"
     loading-screen="enabled: false;"
     arjs="trackingMethod: best; sourceType: webcam; debugUIEnabled: false; patternRatio: 0.7;"
     id="scene"
     embedded
     init-marker
     >
     <a-entity camera></a-entity>
     </a-scene>
     :
     <Grid container justify="center" alignItems="center" className={classes.container}>
      <Grid item>
       <Button color="primary" onClick={markersData.init}>{t('tracker.init')}</Button>
      </Grid>
     </Grid>
    }
   </div>
  )
})


export default MarkerTracker
