import React from 'react'
import MarkerTracker from './MarkerTracker'

function App() {
  return (
   <MarkerTracker />
 )
}

export default App
